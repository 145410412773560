<template>
    <div v-if="hasPermission">
        <!-- Filters -->
        <special-item-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" @filter="fetchSpecialItem" />

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePageSpecialItems({ page: 1, itemsPerPage: $event })" />
                        <label>entries</label>
                    </b-col>
                </b-row>
            </div>

            <b-table ref="refUserListTable" class="position-relative pb-3" :items="specialItems" responsive :per-page="perPage" :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found">
                <!-- Column: Item -->
                <template #cell(item)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar size="32" :src="['banner', 'featured'].includes(data.item.appSection.type) ? data.item.thumbnail : data.item.media.thumbnail" :text="avatarText(data.item.name)" :to="{ name: 'music-special-item-edit', params: { id: data.item.id } }" />
                        </template>
                        <div class="font-weight-bold d-block text-nowrap">
                            {{ data.item.appSection ? data.item.appSection.summary : "" }}
                        </div>
                        <small class="text-muted">{{ data.item.type }}</small>
                    </b-media>
                </template>
                <!-- Column: Media -->
                <template #cell(media)="data">
                    <div class="d-flex align-items-center">
                        <span class="mr-50">
                            <i class="WMi-cog" />
                        </span>
                        <div class="text-nowrap">
                            <div class="font-weight-bold d-block text-nowrap">
                                {{ data.item.media.name_ar }}
                            </div>
                            <small class="text-muted">{{ data.item.media.name ? data.item.media.name : data.item.media.title }}</small>
                        </div>
                    </div>
                </template>
                <!-- Column: Link -->
                <template #cell(link)="data">
                    <div class="text-nowrap">
                        <span>
                            <i class="WMi-link" />
                        </span>
                        <component :is="data.item.media.link ? 'b-link' : 'span'" :href="data.item.media.link" class="align-text-top text-capitalize">{{ data.item.link ? data.item.link : "not link" }} </component>
                    </div>
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>
                        <b-dropdown-item :to="{ name: 'moods-special-item-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteSpecialItem(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                         <b-dropdown-item @click="sendToTop(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Send to Top</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageSpecialItems({ page: $event, itemsPerPage: perPage })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
    <div v-else class="text-center">
        <h1>⛔ You are not Allowed to be here ⛔</h1>
    </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import SpecialItemRepository from "@/abstraction/repository/specialItemRepository";
import SpecialItemListFilters from "./SpecialItemListFilters.vue";
import useSpecialItemList from "./useSpecialItemList";
import userStoreModule from "../user/userStoreModule";

const specialItemRepository = new SpecialItemRepository();
export default {
    components: {
        SpecialItemListFilters,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = "app-user";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        const roleOptions = [
            { label: "Admin", value: "admin" },
            { label: "Author", value: "author" },
            { label: "Editor", value: "editor" },
            { label: "Maintainer", value: "maintainer" },
            { label: "Subscriber", value: "subscriber" },
        ];

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];

        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
        ];

        const {
            fetchSpecialItem,
            tableColumns,
            perPage,
            currentPage,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            pagination,
            specialItems,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useSpecialItemList();
        const blankForm = ref({
            app_section_id: {
                type: "like",
                val: 9,
            },
            featureable_type: {
                type: "like",
                val: null,
            },
            link: {
                type: "like",
                val: null,
            },
        });
        onMounted(() => {
            // fetchSpecialItem({ page: 1, itemsPerPage: perPage.value }, blankForm.value);
            fetchSpecialItem({});
        });

        const changePageSpecialItems = async (paginate) => {
            await fetchSpecialItem(paginate);
        };
        const sendToTop = async (id) => {
            specialItemRepository.sendToTop(id).then(() => {
                window.location.reload();
            });
        };
        const hasPermission = ref(false);
        const checkPermissions = () => {
            let logedInUserPermissions = JSON.parse(localStorage.getItem("userData")).permissions.map((item) => {
                return item.name;
            });
            if (logedInUserPermissions.includes("Mood.Home.list")) {
                hasPermission.value = true;
            }
        };
        checkPermissions();
        return {
            // Sidebar
            sendToTop,
            hasPermission,
            specialItems,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            changePageSpecialItems,
            fetchSpecialItem,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            avatarText,
            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            roleOptions,
            planOptions,
            statusOptions,
        };
    },
    methods: {
        async deleteSpecialItem(specialItemId) {
            await specialItemRepository.delete(specialItemId);
            const index = this.specialItems.findIndex((x) => x.id === specialItemId);
            this.$delete(this.specialItems, index);
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
