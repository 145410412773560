import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData } from "../resources/specialItemResource";

export default class SpecialItemRepository {
    async index(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexSpecialItem"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async musicIndex(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexMusicSI"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async moodsIndex(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexMoodsSI"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async composerIndex(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexComposerSI"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async videoIndex(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexVideoSI"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async playlistIndex(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexPlaylistSI"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async bannersIndex(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexBannersSI"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async newsIndex(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexNewsSI"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async latestIndex(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexLatestSI"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async radioIndex(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexRadioSI"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async featuredIndex(data) {
        const params = setQuery(data);
        const response = await axios.get(url("indexfeaturedSI"), { params });
        if (response && response.status === 200) {
            return getArray(response.data);
        }
    }
    async show(specialItemId) {
        const response = await axios.get(url("showSpecialItem", { specialItem: specialItemId }));
        if (response.status === 200) {
            return getJson(response.data.data);
        }
    }

    async store(data) {
        const json = setData(data);
        const response = await axios.post(url("storeSpecialItem"), json);
        if (response && response.status === 201) {
            return getJson(response.data);
        }
    }

    async update(specialItemId, data) {
        const json = setData(data);
        const response = await axios.put(url("updateSpecialItem", { specialItem: specialItemId }), json);
        if (response.status === 200) {
            return getJson(response.data.data);
        }
    }

    async delete(specialItemId) {
        await axios.delete(url("destroySpecialItem", { specialItem: specialItemId }));
    }
    async sendToTop(specialItemId) {
        const response = await axios.put(url("sendToTopHome", { id: specialItemId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }
}
