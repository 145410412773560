import { SetPagination, SetQueriesObject } from "@/utils/setQueriesObject";

export const getJson = (data) => ({
    id: data.id,
    placeholder: data.placeholder,
    image: data.image,
    thumbnail: data.thumbnail,
    featureable_id: data.featureable_id,
    featureable_type: data.featureable_type,
    created_at: data.created_at,
    app_section_id: data.app_section_id,
    link: data.link,
    appSection: data.appSection
        ? {
              id: data.appSection.id,
              summary: data.appSection.summary,
              title: data.appSection.title,
              type: data.appSection.type,
          }
        : {},
    media: data.media
        ? {
              id: data.media.id,
              name: data.media.name,
              title: data.media.title,
              name_ar: data.media.name_ar,
              description: data.media.description,
              lyric: data.media.lyric,
              genre_id: data.media.genre_id,
              artist_id: data.media.artist_id,
              video_link: data.media.video_link,
              link: data.media.link,
              published_at: data.media.published_at,
              length: data.media.length,
              played_count: data.media.played_count,
              exclusive: data.media.exclusive,
              genre: data.media.genre
                  ? {
                        id: data.media.id,
                        name: data.media.name,
                        name_ar: data.media.name_ar,
                        subject: data.media.subject,
                        subject_ar: data.media.subject_ar,
                        image: data.media.image,
                        thumbnail: data.media.thumbnail,
                    }
                  : {},
              artist: data.media.artist
                  ? {
                        id: data.media.id,
                        name: data.media.name,
                        bio: data.media.bio,
                        playedCount: data.media.playedCount,
                        name_ar: data.media.name_ar,
                        first_name_length: data.media.first_name_length,
                        first_name: data.media.first_name,
                        last_name: data.media.last_name,
                        email: data.media.email,
                        email_verified_at: data.media.email_verified_at,
                        is_artist: data.media.is_artist,
                        cell_number: data.media.cell_number,
                        city_id: data.media.city_id,
                        country_id: data.media.country_id,
                        genre_id: data.media.genre_id,
                        image: data.media.image,
                        thumbnail: data.media.thumbnail,
                    }
                  : {},
              music: data.media.music,
              image: data.media.image,
              thumbnail: data.media.thumbnail,
              hasFavorite: data.media.hasFavorite,
          }
        : {},
});
export const getArray = ({ data, meta }) => {
    // console.log('dfs');
    const pagination = SetPagination(meta);
    data = data.map((specialItem) => getJson(specialItem));
    return { data, pagination };
};
export const setData = (data) => ({
    placeholder: data.placeholder,
    app_section_id: data.app_section_id,
    featureable_id: data.featureable_id,
    featureable_type: data.featureable_type,
    file_batch_id: data.file_batch_id,
    link: data.link,
});
export const setQuery = (data) => SetQueriesObject(data);
